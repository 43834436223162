import _superPropBase from "./superPropBase.js";
var exports = {};
var superPropBase = _superPropBase;
function _get() {
  return (exports = _get = "undefined" != typeof Reflect && Reflect.get ? Reflect.get.bind() : function (e, t, r) {
    var p = superPropBase(e, t);
    if (p) {
      var n = Object.getOwnPropertyDescriptor(p, t);
      return n.get ? n.get.call(arguments.length < 3 ? e : r) : n.value;
    }
  }, exports.__esModule = true, exports["default"] = exports), _get.apply(null, arguments);
}
exports = _get, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;